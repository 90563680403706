<template>
    <div class="foot">
        <div class="container">
            <div class="index_foot">
                <div class="foot_div" >
                    <div class="footleftall">
                        <div class="footleft footlefton">
                            <!-- <h3><img src="../assets/images/zlogo.png" alt=""></h3> -->
                            <div class="foot_addressshow" v-for="(item,index) in list" :key="index" :style="item.type == type?'display:block':'display:none'">
                                <h3>{{item.title}}</h3>
                                <div class="foot_addresflex">
                                    <div v-if="item.address">
                                        <img src="../assets/images/foot_address.png" alt="">
                                        <p>{{item.address}}</p>
                                    </div>
                                    <div v-if="item.phone" v-show="item.phone == '' || item.phone == null?false:true">
                                        <img src="../assets/images/foot_phone.png" alt="">
                                        <p>{{item.phone}}</p>
                                    </div>
                                    <!-- <div v-for="titem in item.dynamicItem" :key="titem.contact" style="min-width: 100%;flex: 1;padding-right: 0;" v-show="titem.contact == '' || titem.contact == null?false:true">
                                        <img style="margin-left: auto;" src="../assets/images/foot_phone.png" alt="" >
                                        <p>{{titem.contact}}</p>
                                    </div> -->
                                    <div v-for="titem in item.dynamicItem" :key="titem.contact" style="flex: 1;padding-right: 0;" v-show="titem.contact == '' || titem.contact == null?false:true">
                                        <img style="margin-left: auto;" src="../assets/images/foot_phone.png" alt="" >
                                        <p>{{titem.contact}}</p>
                                    </div>
                                </div>
                                <div class="foot_addresflex">
                                    <div v-if="item.email">
                                        <img src="../assets/images/foot_mail.png" alt="">
                                        <p>{{item.email}}</p>
                                    </div>
                                    <div  v-if="item.website">
                                        <img src="../assets/images/foot_web.png" alt="">
                                        <p>{{item.website}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footright">
                        <div class="dbgs">
                            <span class="icon-xiajiantou">{{$t('home.Worldwide')}}</span>
                            <ul class="dbgssub">
                                <li class="m" v-for="item in slist" :key="item.link"><a target="_blank" :href="item.link?item.link:''">{{item.title}}</a></li>
                            </ul>
                        </div>
                        <div class="mapBoxs">
                            <div class="mppiont mppiont1" @mouseenter="showlist(1)">
                                <span class="wzns">总部</span>
                                <div class="area-box on">
                                    <span class="dot"></span>
                                    <span class="pulse delay-01"></span>
                                    <span class="pulse delay-02"></span>

                                </div>
                            </div>
                            <div class="mppiont mppiont2" @mouseenter="showlist(2)">
                                <span class="wzns">越南</span>
                                <div class="area-box on">
                                    <span class="dot"></span>
                                    <span class="pulse delay-03"></span>
                                    <span class="pulse delay-05"></span>
                                </div>
                            </div>
                            <div class="mppiont mppiont3" @mouseenter="showlist(3)">
                                <span class="wzns">德国</span>
                                <div class="area-box on">
                                    <span class="dot"></span>
                                    <span class="pulse delay-06"></span>
                                    <span class="pulse delay-05"></span>
                                    <span class="pulse delay-04"></span>

                                </div>
                            </div>
                            <div class="mppiont mppiont4" @mouseenter="showlist(5)">
                                <span class="wzns">美国</span>
                                <div class="area-box on">
                                    <span class="dot"></span>
                                    <span class="pulse delay-03"></span>
                                    <span class="pulse delay-01"></span>
                                    <span class="pulse delay-02"></span>

                                </div>
                            </div>
                            <div class="mppiont mppiont5" @mouseenter="showlist(4)">
                                <span class="wzns">加拿大</span>
                                <div class="area-box on">
                                    <span class="dot"></span>
                                    <span class="pulse delay-06"></span>
                                    <span class="pulse delay-04"></span>

                                </div>
                            </div>
                            <img src="../assets/images/foot-map.png">
                        </div>
                    </div>
                </div>
                
                <div class="foot_a">
                    <a class="facebook wechatimga" style="position:relative">
                        <img src="../assets/images/foot1.png">
                        <div class="wechatimg">
                            <img src="../assets/images/bowaigzh.jpg">
                        </div>
                    </a>
                    <a target="_blank" href="https://www.zhihu.com/org/bo-wei-he-jin/posts" class="facebook"><img src="../assets/images/sinafx.png"></a>
                    <a target="_blank" href="https://www.facebook.com/BowayAlloy/" class="facebook"><img src="../assets/images/facebook.png"></a>
                    <a target="_blank" href="https://www.linkedin.com/company/boway-alloy/?viewAsMember=true" class="facebook"><img src="../assets/images/linkedin.png"></a>
                </div>
                <p class="foot_note">
                    <router-link to="/product_one?id=1&title=精密板带">{{$t('home.footProducts')}}</router-link>
                    <router-link to="/news">{{$t('home.footNews')}}</router-link>
                    <router-link to="/site_map">{{$t('home.footSitemap')}}</router-link>
                    <router-link to="/copyright_notice">{{$t('home.footCopyright')}}</router-link>
                    <router-link to="/privacy_zc">{{$t('home.footPrivacy')}}</router-link>
                    <span style="margin-left: auto;">{{$t('home.footbeian')}}</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'


export default {
        name: 'foot', 
        data(){
            return{
                list:'',
                type:'1',
                slist:''
            }
        },
        created(){
            this.$get(this.apiUrl + 'home/homePage/contactUsList').then((response) => {
                this.list =  response.data;
            });
            this.$get(this.apiUrl + 'home/homePage/globalDistribution').then((response) => {
                this.slist =  response.data;
            });
        },

        mounted(){
            $(function(){
                var $copy_bottom_right = $('.dbgs'),
                $translate = $copy_bottom_right.find('.dbgssub');
                $copy_bottom_right.hover(function() {
                    $translate.stop().slideDown(500);
                }, function() {
                    $translate.stop().slideUp(500);
                })
            })
        },

        methods:{
            showlist(type){
                this.type = type
            },
        }

        
}
</script>

<style lang="less" scoped>
a{
    text-decoration: none;
}
    .foot{
    background: #f7f7f7;
}

.index_foot{
    max-width: 1900px;
    margin: 0 auto;
    padding: 0 80px;
    padding-top: 40px;
    padding-bottom: 30px;
}

.footleft{
    max-width: 750px;
    min-height: 360px;
    display: none;
}

.footlefton{
    display: block;
}

.footleft>h3{
    font-size: 24px;
    color: #ED6C00;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 30px;
}

.foot_addressshow{
    margin-top: 20px;
}

.foot_addressshow h3{
    color: #333333;
    font-size: 18px;
    line-height: 30px;
}

.foot_addresflex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.foot_addresflex>div:nth-child(2n+1){
    flex: 2;
    padding-right: 50px;
}

.foot_addresflex>div:nth-child(2n){
    flex: 1;
}

.foot_addresflex>div{
    display: flex;
    margin-top: 15px;
}

.foot_addresflex>div>img{
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-right: 8px;
}

.foot_addresflex>div>p{
    line-height: 18px;
    font-size: 14px;
    color: #666666;
    max-width: 400px;
    min-width: 250px;
    // white-space: nowrap;
}

.foot_a{
    display: flex;
    margin-top: 20px;
}

.foot_a a{
    margin-right: 15px;
    display: block;
}

.foot_a a img{
    width: 30px;
    height: 30px;
}

.foot_note{
    margin-top: 25px;
    display: flex;
    color: #333333;
    font-size: 14px;
    line-height: 30px;
}

.foot_note a{
    margin-right: 30px;
}

.foot_div{
    display: flex;
    // flex-wrap: wrap;
}

.footright{
    width: 501px;
    margin-left: auto;
}

.dbgs {
	display: inline-block;
	width: 168px;
	text-align: center;
	border: 1px solid #666;
	border-radius: 15px;
	-webkit-border-radius: 15px;
	line-height: 35px;
	font-size: 12px;
	cursor: pointer;
	position: relative;
	z-index:11;
    margin-left: calc(100% - 168px);
}

.dbgs span {
	font-size: 14px;
	color: #666;
	display: inline-block;
}

.dbgs .icon-xiajiantou:before {
	float: right;
	margin-left: 15px;
}

.dbgssub {
	background: #fff;
	position: absolute;
	width: 146%;
	bottom: 0;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 5px 20px rgba(255, 255, 255, 0.2);
	left: -23px;
	display: none;
	text-align: center;
}

.dbgssub a {
	color: #333;
	font-size: 13px;
	padding: 6px 0;
	display: block;
}

.dbgssub li {
	border-bottom: 1px solid #dcdcdc;
}

.dbgssub li.m:hover {
	background: #f1f1f1;
}

.mapBoxs {
	width: 100%;
	/* height: 254px; */
	/* background: url(../image/foot-map.png) no-repeat right center; */
	background-size: cover;
	margin: 30px 0 20px;
	float: right;
}

@-webkit-keyframes warn{

0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}

100%{-webkit-transform:scale(1);transform:scale(1);opacity:0}

}

@-moz-keyframes warn{

0%{-moz-transform:scale(0);transform:scale(0);opacity:1}

100%{-moz-transform:scale(1);transform:scale(1);opacity:0}

}

@-o-keyframes warn{

0%{-o-transform:scale(0);transform:scale(0);opacity:1}

100%{-o-transform:scale(1);transform:scale(1);opacity:0}

}

@keyframes warn{

0%{-webkit-transform:scale(0);-moz-transform:scale(0);-o-transform:scale(0);transform:scale(0);opacity:1}

100%{-webkit-transform:scale(1);-moz-transform:scale(1);-o-transform:scale(1);transform:scale(1);opacity:0}

}

.mapBoxs{height:259px; position:relative;}
.mppiont{ box-sizing:content-box; position:absolute; width:12px; height:12px;  cursor:pointer;}
.mppiont1{ left:395px; top:110px;}
.mppiont2{ left:374px; top:136px;}
.mppiont3{ left:240px; top:76px;}
.mppiont4{ left:63px; top:107px;}
.mppiont5{ left:120px; top:82px;}
.wzns{ display:none;}

.area-box .dot{position:absolute;left:0;top:0;width:8px;height:8px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background:#a2a9b4;opacity:1;filter:alpha(opacity=100)}
.area-box.on .dot {
    background: #ED6C00;
}

.area-box .pulse{position:absolute;top:-28px;left:-28px;height:66px;width:66px;border:2px solid #b7b7b7;-webkit-border-radius:48px;-moz-border-radius:48px;border-radius:48px;-webkit-box-shadow:0 0 4px #82878f,0 0 10px #82878f inset;-moz-box-shadow:0 0 4px #82878f,0 0 10px #82878f inset;box-shadow:0 0 4px #82878f,0 0 10px #82878f inset;opacity:.12;filter:alpha(opacity=0);-webkit-animation:warn 2s ease-out both;-moz-animation:warn 2s ease-out both;-o-animation:warn 2s ease-out both;animation:warn 2s ease-out both;-webkit-animation-iteration-count:infinite;-moz-animation-iteration-count:infinite;-o-animation-iteration-count:infinite;animation-iteration-count:infinite;background:0 0}

.area-box.on .pulse{border-color:#ED6C00;top:-42px;left:-42px;height:88px;width:88px;-webkit-box-shadow:0 0 12px #f58322,0 0 20px #ED6C00 inset;-moz-box-shadow:0 0 12px #f58322,0 0 20px #ED6C00 inset;box-shadow:0 0 12px #f58322,0 0 20px #ED6C00 inset}

.area-box .delay-01{-webkit-animation-delay:0;-moz-animation-delay:0;-o-animation-delay:0;animation-delay:0}

.area-box .delay-02{-webkit-animation-delay:.4s;-moz-animation-delay:.4s;-o-animation-delay:.4s;animation-delay:.4s}

.area-box .delay-03{-webkit-animation-delay:.8s;-moz-animation-delay:.8s;-o-animation-delay:.8s;animation-delay:.8s}

.area-box .delay-04{-webkit-animation-delay:1.2s;-moz-animation-delay:1.2s;-o-animation-delay:1.2s;animation-delay:1.2s}

.area-box .delay-05{-webkit-animation-delay:1.6s;-moz-animation-delay:1.6s;-o-animation-delay:1.6s;animation-delay:1.6s}

.area-box .delay-06{-webkit-animation-delay:2s;-moz-animation-delay:2s;-o-animation-delay:2s;animation-delay:2s}

.area-box .delay-07{-webkit-animation-delay:2.4s;-moz-animation-delay:2.4s;-o-animation-delay:2.4s;animation-delay:2.4s}

.area-box .delay-08{-webkit-animation-delay:-.4s;-moz-animation-delay:-.4s;-o-animation-delay:-.4s;animation-delay:-.4s}

.area-box .delay-09{-webkit-animation-delay:-.8s;-moz-animation-delay:-.8s;-o-animation-delay:-.8s;animation-delay:-.8s}

.area-box .delay-10{-webkit-animation-delay:-1.2s;-moz-animation-delay:-1.2s;-o-animation-delay:-1.2s;animation-delay:-1.2s}

.area-box .delay-11{-webkit-animation-delay:4s;-moz-animation-delay:4s;-o-animation-delay:4s;animation-delay:4s}

.conmap63{ width:962px; height:500px; position:relative; background:url("../assets/images/aboutmaps.jpg")/*tpa=https://www.bowayalloy.com/public/static/pc/tmp/aboutmaps.jpg*/ center no-repeat; display:inline-block; vertical-align:top;}
.conmap63m{ position:absolute; overflow:hidden; }
.conmap63mp{ width:160px; float:left; margin:0 20px; text-align:center; line-height:22px; font-size:12px;}
.conmap63mp p{ padding-top:5px;}
.conmap63mp i{ display:none;}
.conmap63m1,.conmap63m3{ width:200px;}
.conmap63m2{ width:800px;}
.conmap63m4,.conmap63m5{ width:400px;}
.conmapbox{ background:#fff; text-align:center;}
	
.mppiont61{ left:246px;top:212px;}
.mppiont62{ left:744px;top:252px;}
.mppiont63{ left:158px;top:245px;}
.mppiont64{ left:463px;top:197px;}
.mppiont65{ left:705px;top:276px;}
.conmap63 .area-box.on .pulse{ width:68px; height:68px; top:-31px; left:-31px;}


.conmap63m1{ left:130px; bottom:360px;}
.conmap63m2{ left:220px; bottom:360px;}
.conmap63m3{ left:-70px; bottom:255px;}
.conmap63m4{ left:300px; bottom:360px;}
.conmap63m5{ left:505px; bottom:30px;}

.conmap63m{ transition:all 0.6s ease; opacity:0; transform:scale(0); margin-bottom:-10px; }
.conmap63m.on{ opacity:1;transform:scale(1); margin-bottom:0;}

.wechatimg{
    width: 122px;
    height: 122px;
    padding: 6px;
    background: #fff;
    position: absolute;
    top: -125px;
    left: -44px;
    transition: 0.5s all;
    opacity: 0;
    pointer-events: none;


    img{
        width: 110px!important;
        height: 110px!important;
        
    }
}

.wechatimga:hover .wechatimg{
    opacity: 1;
    pointer-events:all;
}

</style>